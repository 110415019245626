/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  font-family: '小塚ゴシック Pro','Kozuka Gothic Pro','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: $color;
  background: $bg;
  font-size: 15px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  @include mq(sp) {
    font-size: 13px;
  }
}
a {
  color: $aColor;
  @include txtLine(hLine);
  @include mq(sp) {
    color: black;
  }
}
strong {
  font-weight: bold;
}

/* wrapper
-------------------------*/
.wrapper {
  position: relative;
  min-width: 877px;
  @include mq(sp) {
    min-width: 100%;
    width: 100%;
  }
}
/* header
-------------------------*/
.header {
  position: relative;
  padding: 6px 28px 0;
  background: #fff;
  z-index: 99999;
  height: 67px;
  box-sizing: border-box;
  @include clearfix;
  @include mq(sp) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 10px 0 10px;
    height: 70px;
    box-shadow: 0 0px 5px rgba(#4d4d4d,.6);
  }
  &__logo {
    display: block;
    margin-top: 9px;
    float: left;
    img {
      display: block;
      width: 100%;
    }
    @include mq(sp) {
      float: none;
      width: 200px;
      margin: 0 auto 6px;
    }
    
  }
}
.header-nav {
  float: right;
  display: flex;
  align-items: baseline;
  @include mq(sp) {
    align-items: center;
    float: none;
    justify-content: space-around;
  }
  &__item {
    font-size: 16px;
    margin: 0 10px;
    letter-spacing: 0.16em;
    @include mq(sp) {
      font-size: 11px;
      letter-spacing: 0;
      margin: 0 5px;
    }
    &--contact {
      font-size: 14px;
      width: 132px;
      height: 28px;
      border: 1px solid #005eaa;
      border-radius: 3px;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      line-height: 28px;
      letter-spacing: 0.1em;
      @include mq(sp) {
        font-size: 11px;
        width: 95px;
        height: 22px;
        line-height: 22px;
      }
      .header-nav__link {
        transition: all .3s;
        &:hover {
          background: #005eaa;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    &--phone {
      font-size: 31px;
      padding-top: 4px;
      padding-left: 33px;
      font-weight: bold;
      background-image: url("../img/top/ico_phone_01.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 28px auto;
      letter-spacing: 0.08em;
      margin-right: 0;
      @include mq(sp) {
        font-size: 13px;
        padding-left: 20px;
        background-size: 15px auto;
      }
    }
  }
  &__link {
    color: #005eaa;
    display: block;
  }
}
/* gnav
-------------------------*/
.gnav {
  position: absolute;
  top: 67px;
  left: 50%;
  width: 837px;
  transform: translateX(-50%);
  z-index: 99990;
  @include mq(sp) {
    top: 70px;
    width: 100%;
    left: 0;
    transform: translateX(0);
  }
  &__list {
    display: flex;
    justify-content:space-around;
    width: 837px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    background: #005eaa;
    @include mq(sp) {
      width: 100%;
      flex-wrap:wrap;
      padding: 0;
    }
  }
  &__item {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1em;
    @include mq(sp) {
      font-size: 11px;
      text-align: center;
      box-sizing: border-box;
      &:nth-child(1) {
        flex-basis: 26%;
        border-bottom: 1px solid #fff;
      }
      &:nth-child(2) {
        flex-basis: 26%;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
      }
      &:nth-child(3) {
        flex-basis: 48%;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
      }
      &:nth-child(4) {
        flex-basis: 60%;
      }
      &:nth-child(5) {
        border-left: 1px solid #fff;
        flex-basis: 40%;
      }
    }
  }
  &__link {
    display: block;
    position: relative;
    height: 72px;
    color: #fff;
    line-height: 72px;
    box-sizing: border-box;
    text-decoration: none;
    transition: .3s;
    @include mq(sp) {
      height: 40px;
      line-height: 40px;
      white-space: nowrap;
    }
    &:after {
      position: absolute;
      bottom: 15px;
      left: 50%;
      content: '';
      width: 0;
      height: 1px;
      background-color: #fff;
      transition: .3s;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      @include mq(sp) {
        display: none;
      }
    }
    &:hover {
      text-decoration: none;
      &:after {
        width: 100%;
      }
    }
  }
}
/* breadcrumb
-------------------------*/
.breadcrumb {
  padding: 40px 0;
  @include mq(sp) {
    display: none;
  }
  li {
    display: inline-block;
    color: #b2b2b2;
    a {
      color: #b2b2b2;
    }
    +li {
      margin-left: 6px;
      &:before {
        margin-right: 6px;
        content: ">";
      }
    }
  }
}
/* contents
-------------------------*/
.contents {
  margin-top: 72px;
  @include mq(sp) {
    padding-top: 171px;
    margin-top: 0;
  }
  img {
    @include mq(sp) {
      display: block;
      max-width: 100%;
      height: auto;
      width: auto;
      margin: 0 auto;
    }
  }
}

/* footer
-------------------------*/
.footer {
  position: relative;
  margin-bottom: 85px;
  padding: 80px 0 255px;
  background-image: url("../img/common/bg_footer_01.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 837px auto;
  @include mq(sp) {
    padding: 40px 0 140px;
    background-size: 100% auto;
  }
  &:before {
    position: absolute;
    bottom: 26px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #005eaa;
    content: "";
    z-index: -1;
  }
  &__logo {
    display: block;
    margin-bottom: 25px;
    @include fadeAnime;
    @include mq(sp) {
      margin-bottom: 15px;
    }
  }
  &__address {
    margin-bottom: 50px;
    letter-spacing: 0.13em;
    @include mq(sp) {
      margin-bottom: 30px;
    }
  }
  &__link {
    padding: 22px 0;
    background: #005eaa;
    @include mq(sp) {
      padding: 20px 0 10px;
    }
  }
  &__list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mq(sp) {
      display: block;
    }
  }
  &__item {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 1.75;
    @include mq(sp) {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 11px;
      border-right: 1px solid #fff;
      margin-right: 12px;
      padding-right: 12px;
    }
    &:first-child {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
    &:before {
      position: absolute;
      top: 0;
      left: -9px;
      width: 1px;
      height: 100%;
      background: #fff;
      content: "";
      @include mq(sp) {
        display: none;
      }
    }
    a {
      color: #fff;
    }
  }
  &__copyright {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    color: #005eaa;
    letter-spacing: 0.13em;
    font-size: 9px;
    white-space: nowrap;
  }
}

// z-index
// =============================================================
//重なり順にリスト
$z: contents , header;
//.header {
// z-index: index($z, header);
//}