//-----------------------------
// 変数宣言用ファイル
//-----------------------------

//-----------------
//フォントファミリー
// ※unquote($familyA)で呼び出し
//-----------------
$familyA: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif";
$familyB: "'ヒラギノ明朝 Pro W6', 'Hiragino Mincho Pro', 'HGS明朝E', 'ＭＳ Ｐ明朝', serif";
$familyC: "'メイリオ', Meiryo,  'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif";
$familyD: "'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif";

//-----------------
// 基本テキストカラー
//-----------------
$color: #4d4d4d;
//-----------------
//-----------------
// リンクテキストカラー
//-----------------
$aColor: #4d4d4d;
//-----------------
// 基本背景カラー
//-----------------
$bg: #fff;

//青
$blue: #005ea9;