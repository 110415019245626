/* ------------------------------------------------
  TOP
---------------------------------------------- */
.mv {
  position: relative;
  z-index: 80;
  clear: both;
  height: calc(100vh - 67px);
  overflow: hidden;
  @include mq(sp) {
    top: 70px + 81px;
    height: calc(100vh - 151px);
  }
  &__catch {
    position: absolute;
    width: 837px;
    left: 50%;
    bottom: 160px;
    transform: translateX(-50%);
    font-size: 34px;
    font-weight: bold;
    text-shadow: 
    #fff 2px 0px 0px, #fff -2px 0px 0px,
    #fff 0px -2px 0px, #fff 0px 2px 0px,
    #fff 2px 2px 0px, #fff -2px 2px 0px,
    #fff 2px -2px 0px, #fff -2px -2px 0px,
    #fff 1px 2px 0px, #fff -1px 2px 0px,
    #fff 1px -2px 0px, #fff -1px -2px 0px,
    #fff 2px 1px 0px, #fff -2px 1px 0px,
    #fff 2px -1px 0px, #fff -2px -1px 0px,
    #fff 1px 1px 0px, #fff -1px 1px 0px,
    #fff 1px -1px 0px, #fff -1px -1px 0px;
    color: #000;
    
    @include mq(sp) {
      font-size: 20px;
      width: 80%;
      max-width: 320px;
      left: 0;
      transform: translateX(0);
      padding: 0 20px;
      box-sizing: border-box;
      bottom: 120px;
      img {
        display: block;
        width: 100%;
      }
    }
   
  }
  &__txt {
    position: absolute;
    width: 837px;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    font-size: 15px;
    color: #000;
    letter-spacing: 0.09em;
    text-shadow: 
    #fff 2px 0px 0px, #fff -2px 0px 0px,
    #fff 0px -2px 0px, #fff 0px 2px 0px,
    #fff 2px 2px 0px, #fff -2px 2px 0px,
    #fff 2px -2px 0px, #fff -2px -2px 0px,
    #fff 1px 2px 0px, #fff -1px 2px 0px,
    #fff 1px -2px 0px, #fff -1px -2px 0px,
    #fff 2px 1px 0px, #fff -2px 1px 0px,
    #fff 2px -1px 0px, #fff -2px -1px 0px,
    #fff 1px 1px 0px, #fff -1px 1px 0px,
    #fff 1px -1px 0px, #fff -1px -1px 0px;
    @include mq(sp) {
      width: 100%;
      left: 0;
      bottom: 40px;
      transform: translateX(0);
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 13px;
    }
  }
}
.top-slider {
  height: 100%;
  background: #fff;
  &__item {
    width: 100%;
    height: calc(100vh - 67px);
    transform: scale(1.1);
    @include mq(sp) {
      height: calc(100vh - 151px);
    }
    img {
     visibility: hidden;
    }
  }
  &__item-inner {
    width: 100%;
    height: calc(100vh - 67px);
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    //opacity: 0;
    transform: scale(1);
    transition: opacity 0ms linear 1500ms, transform 0ms linear 1500ms;
    @include mq(sp) {
      height: calc(100vh - 151px);
    }
  }
  .top-slider__item.slick-current .top-slider__item-inner {
    opacity: 1;
    transform: scale(1.07);
    transition: opacity 1500ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 6000ms linear;
  }
  .first-zoom {
    opacity: 1;
    transform: scale(1.05);
    transform: scale(1);
    transition: transform 8000ms ease-out;
  }
}
.top-work {
  padding-top: 95px;
  text-align: center;
  overflow: hidden;
  @include mq(sp) {
    padding-top: 151px + 40px;
  }
  &__head {
    margin-bottom: 40px;
    font-size: 19px;
    letter-spacing: 0.07em;
    color: #000;
    font-weight: bold;
    @include mq(sp) {
      font-size: 13px;
    }
    span {
      margin-top: 20px;
      padding: 0 15px;
      display: inline-block;
      background-image: url("../img/common/line_01.png");
      background-size: auto 12px;
      background-repeat: repeat-x;
      background-position: left 85%;
      font-size: 30px;
      letter-spacing: 0.09em;
      @include mq(sp) {
        margin-top: 0;
        font-size: 20px;
      }
    }
  } 
  &__list {
    display: flex;
    margin: 0 -10px;
    justify-content: space-around;
    @include mq(sp) {
      margin: 0 5px;
      flex-wrap: wrap;
    }
  }
  &__item {
    position: relative;
    margin: 0 10px;
    width: 33.3%;
    height: 364px;
    @include mq(sp) {
      width: 50%;
      height: 200px;
      margin: 0 0 15px;
      padding: 0 5px;
      box-sizing: border-box;
    }
    &--01 {
      .top-work__link {
        &:after {
          background-image: url("../img/top/img_work_01.png");
        }
      }
    }
    &--02 {
      .top-work__link {
        &:after {
          background-image: url("../img/top/img_work_02.png");
        }
      }
    }
    &--03 {
      .top-work__link {
        &:after {
          background-image: url("../img/top/img_work_03.png");
        }
      }
    }
  }
  &__link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include mq(sp) {
      padding: 0 10px;
      box-sizing: border-box;
    }
    &:before {
      position: absolute;
      top: 58px;
      left: 50%;
      width: 93px;
      height: 101px;
      background-image: url("../img/top/ico_point_01.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 93px auto;
      transform: translateX(-50%);
      content: "";
      z-index: 3;
      @include mq(sp) {
        top: 5px;
        left: 50%;
        width: 50px;
        transform: translateX(-50%);
        //opacity: 0.4;
        background-size: 50px auto;
      }
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      background-position:  center center;
      transition: all .3s;
      z-index: 2;
    }
    &:hover {
      text-decoration: none;
      &:after {
        transform: scale(1.1);
      }
      .top-work__btn {
        opacity: .7;
      }
    }
  }
  &__ttl {
    position: relative;
    padding-top: 175px;
    margin-bottom: 10px;
    z-index: 4;
    color: #fff;
    font-size: 24px;
    line-height: 1.375;
    letter-spacing: 0.05em;
    font-weight: bold;
    @include mq(sp) {
      padding-top: 70px;
      padding-left: 0;
      font-size: 15px;
    }
    @include mq(ssp) {
      font-size: 13px;
    }
  }
  &__btn {
    position: relative;
    display: block;
    background: #ffff00;
    margin: 0 auto;
    z-index: 4;
    max-width: 280px;
    height: 42px;
    line-height: 42px;
    letter-spacing: 0.05em;
    font-size: 15px;
    transition: all .3s;
    font-weight: bold;
    @include mq(sp) {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 80%;
      transform: translateX(-50%);
      font-size: 11px;
      height: 30px;
      line-height: 30px;
    }
    
    &:before {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 9px;
      height: 11px;
      background-image: url("../img/common/ico_link_01.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
      transform: translateY(-50%);
      content: "";
      @include mq(sp) {
        right: 10px;
      }
    }
  }
}
.top-news {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  @include mq(sp) {
    padding: 40px 0;
  }
  &__head {
    display: inline-block;
    margin-bottom: 35px;
    padding: 0 15px;
    background-image: url("../img/common/line_01.png");
    background-size: auto 12px;
    background-repeat: repeat-x;
    background-position: left 80%;
    font-size: 30px;
    letter-spacing: 0.05em;
    color: #000;
    font-weight: bold;
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 20px;
    }
  }
  &__item {
    border-bottom: 2px dotted #d0d0d0;
  }
  &__link {
    padding: 10px 0;
    display: flex;
    letter-spacing: 0.05em;
    line-height: 2;
    font-size: 14px;
    @include mq(sp) {
      font-size: 13px;
    }
    &:hover {
      text-decoration: none;
      .top-news__ttl {
        text-decoration: underline;
      }
    }
  }
  &__date {
    padding-left: 120px;
    @include mq(sp) {
      padding-left: 0;
    }
  }
  &__ttl {
    padding-left: 35px;
    @include mq(sp) {
      padding-left: 20px;
      text-align: left;
    }
  }
}
.top-contact {
  padding: 30px 0;
  background: #eee;
  text-align: center;
  @include mq(sp) {
    padding: 20px 20px;
  }
  .container {
    padding: 105px 0 40px;
    background: #fff;
    @include mq(sp) {
      padding: 70px 20px 40px;
    }
  }
  &__head {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    padding: 0 15px;
    background-image: url("../img/common/line_01.png");
    background-size: auto 12px;
    background-repeat: repeat-x;
    background-position: left 80%;
    font-size: 30px;
    letter-spacing: 0.05em;
    color: #000;
    font-weight: bold;
    @include mq(sp) {
      font-size: 20px;
    }
    &:before {
      position: absolute;
      top: -74px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 64px;
      height: 66px;
      background-image: url("../img/top/ico_contact_01.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 64px auto;
      @include mq(sp) {
        top: -50px;
        background-size: 48px auto;
        width: 48px;
      }
    }
  }
  &__ttl {
    font-size: 20px;
    letter-spacing: 0.11em;
    font-weight: bold;
    @include mq(sp) {
      font-size: 15px;
    }
  }
  &__phone {
    padding-top: 10px;
    padding-left: 50px;
    font-size: 53px;
    letter-spacing: 0.08em;
    font-weight: bold;
    background-image: url("../img/top/ico_phone_01.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 42px auto;
    color: #4d4d4d;
    @include mq(sp) {
      font-size: 30px;
      padding-bottom: 5px;
      background-size: 34px auto;
      padding-left: 40px;
    }
    @include mq(ssp) {
      font-size: 24px;
      background-size: 28px auto;
    }
  }
  &__note {
    letter-spacing: 0.13em;
    @include mq(sp) {
      font-size: 11px;
    }
    +.top-contact__ttl {
      margin-top: 40px;
    }
  }
  &__btn {
    position: relative;
    display: block;
    width: 100%;
    max-width: 334px;
    height: 56px;
    background: #005eaa;
    border-radius: 5px;
    margin: 20px auto 25px;
    color: #fff;
    line-height: 56px;
    letter-spacing: 0.1em;
    font-size: 16px;
    font-weight: bold;
    @include fadeAnime;
    @include mq(sp) {
      font-size: 13px;
      margin: 5px auto 10px;
    }
    &:before {
      position: absolute;
      top: 50%;
      right: 30px;
      width: 9px;
      height: 11px;
      background-image: url("../img/common/ico_link_02.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
      transform: translateY(-50%);
      content: "";
    }
    &:hover {
      text-decoration: none;
    }
  }
}
