//-----------------------------
// @extend / @mixin専用ファイル
//-----------------------------
// テキスト非表示
//-----------------------------
%off {
  text-indent: 100%; 
  white-space: nowrap; 
  overflow: hidden; 
}
/*clear fix*/
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '';
  }
}
@mixin menu {
  transition: all 300ms;
}
@mixin fadeAnime {
  opacity: 1;
  transition: opacity 300ms;
  &:hover {
    opacity: .7;
    text-decoration: none;
  }
}
@keyframes showAnime{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
//-----------------------------
//img
//-----------------------------
@mixin rwd-img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}
//-----------------------------
//box-sizing
//-----------------------------
%bs {
  box-sizing: border-box;
}
@mixin bs {
  box-sizing: border-box;
}
//-----------------------------
// リンクテキストの下線スタイル 
// a { @include txtLine(line);} //常に下線あり
// a { @include txtLine(hLine);} //hover時に下線あり
// a { @include txtLine(hNoLine);} //hover時に下線なし
// a { @include txtLine(noLine);} //常に下線無し
//-----------------------------
@mixin txtLine($style) {
  @if $style == line {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  @else if $style == hLine {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @else if $style == hNoLine {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @else if $style == noLine {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
//-----------------------------
// 透明度
// div {@include opacity(0.7);}
//-----------------------------
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE);
}
// text-overflow
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// background(retina)
@mixin bg-base {
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
}
//placeholder
@mixin input-placeholder {
  &::-webkit-input-placeholder {
    @content;
    -webkit-font-smoothing: antialiased;
  }
  &:-moz-placeholder {
    @content;
    -moz-osx-font-smoothing: grayscale;
  }
  &::-moz-placeholder {
    @content;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
//------------------
// media query
//------------------
$smallRange: 359px;
$mediumRange: 768px;
$largeRange: 877px;

@mixin mq($media) {
  @if $media == ssp {
    @media screen and (max-width: #{$smallRange}) {
      @content;
    }
  }
  @if $media == sp {
    @media screen and (max-width: #{$mediumRange}) {
      @content;
    }
  }
  @if $media == pc {
    @media screen and (max-width: #{$largeRange}) {
      @content;
    }
  }
}
//@include gradient(#fff, #000, vertical);
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == vertical {
    // vertical
    background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
  }
  @else if $orientation == horizontal {
    // horizontal
    background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
  @else {
    // radial
    background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
    background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
  }
}
// font-size
@function get_vw($size, $viewport:365){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fs($font_size:10){
  font-size: get_vw($font_size);
}
