/* ------------------------------------------------
  page
---------------------------------------------- */
.page {
  &__ttl {
    font-size: 30px;
    font-weight: bold;
    color: $blue;
    letter-spacing: 0.06em;
    @include mq(sp) {
      font-size: 24px;
    }
  }
  &__en-ttl {
    display: block;
    color: $blue;
    font-size: 20px;
    letter-spacing: 0.06em;
    margin-bottom: 60px;
    @include mq(sp) {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
  &__heading {
    color: $blue;
    font-weight: bold;
    font-size: 19px;
    line-height: 2.1;
    letter-spacing: 0.05em;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
  &__txt {
    margin-bottom: 90px;
    line-height: 2.66;
    letter-spacing: 0.05em;
    @include mq(sp) {
      line-height: 2;
      margin-bottom: 60px;
    }
    &--center {
      text-align: center;
    }
    a {
      color: $blue;
    }
    +.page__img {
      margin-top: -50px;
    }
  }
  &__img-ttl {
    margin-bottom: 50px;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 20px;
      overflow: hidden;
      img {
        max-width: inherit;
        width: auto;
        height: 100px;
      }
    }
  }
  &__img {
    margin-bottom: 10px;
  }
}
.item-display {
  position: relative;
  background-image: url("../img/page/bg_item_01.png");
  background-repeat: repeat-x;
  background-size: auto 427px;
  text-align: center;
  margin-bottom: 45px;
  &__logo {
    @include mq(sp) {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
    }
  }
  &__name {
    position: absolute;
    bottom: 40px;
    left: 50%;
    font-size: 14px;
    font-weight: bold;
    margin-left: 110px;
    @include mq(sp) {
      font-size: 10px;
      bottom: 5px;
      white-space: nowrap;
      transform: translateX(-50%);
      margin-left: 0;
    }
  }
}
.page-contact {
  margin-bottom: 100px;
  @include mq(sp) {
    margin-bottom: 60px;
  }
  &__ttl {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;
    @include mq(sp) {
      font-size: 18px;
      white-space: nowrap;
      margin-bottom: 20px;
    }
    
    &-inner {
      position: relative;
      display: inline-block;
      padding: 0 20px;
      background-image: url("../img/common/line_01.png");
      background-size: auto 12px;
      background-repeat: repeat-x;
      background-position: left 80%;
      @include mq(sp) {
        padding: 0 10px;
      }
      &:before {
        position: absolute;
        top: 6px;
        left: -14px;
        transform: rotate(-45deg);
        width: 1px;
        height: 35px;
        background: $color;
        content: "";
        @include mq(sp) {
          top: 2px;
          left: -10px;
          height: 30px;
        }
      }
      &:after {
        position: absolute;
        top: 6px;
        right: -14px;
        transform: rotate(45deg);
        width: 1px;
        height: 35px;
        background: $color;
        content: "";
        @include mq(sp) {
          top: 2px;
          right: -10px;
          height: 30px;
        }
      }
    }
  }
  &__list {
    display: flex;
    justify-content: space-around;
    @include mq(sp) {
      display: block;
    }
    li {
      width: 378px;
      border: 1px solid #86c440;
      border-radius: 5px;
      height: 56px;
      box-sizing: border-box;
      line-height: 56px;
      @include mq(sp) {
        margin: 0 auto 15px;
        width: 100%;
        max-width: 378px;
      }
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    height: 54px;
    line-height: 54px;
    text-decoration: none;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
  &__number {
    line-height: 56px;
    font-size: 26px;
    color: #86c440;
    letter-spacing: 0.12em;
    white-space: nowrap;
    padding-left: 58px;
    background-image: url("../img/page/ico_phone_02.png");
    background-repeat: no-repeat;
    background-position: 20px 8px;
    background-size: 28px auto;
    font-weight: bold;
    @include mq(sp) {
      padding-left: 38px;
      background-size: 18px auto;
      background-position: 15px 15px;
      font-size: 20px;
    }
  }
  &__link {
    display: block;
    background: #86c440;
    text-align: center;
    height: 54px;
    line-height: 54px;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    @include fadeAnime;
  }
  &__note {
    color: #b2b2b2;
    padding-left: 10px;
    font-size: 10px;
    line-height: 1.3;
  }
}