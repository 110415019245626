@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

/*clear fix*/
@keyframes showAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ------------------------------------------------
 	common     
  ---------------------------------------------- */
body {
  font-family: '小塚ゴシック Pro','Kozuka Gothic Pro','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #4d4d4d;
  background: #fff;
  font-size: 15px;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 13px;
  }
}

a {
  color: #4d4d4d;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  a {
    color: black;
  }
}

strong {
  font-weight: bold;
}

/* wrapper
-------------------------*/
.wrapper {
  position: relative;
  min-width: 877px;
}

@media screen and (max-width: 768px) {
  .wrapper {
    min-width: 100%;
    width: 100%;
  }
}

/* header
-------------------------*/
.header {
  position: relative;
  padding: 6px 28px 0;
  background: #fff;
  z-index: 99999;
  height: 67px;
  box-sizing: border-box;
}

.header:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: '';
}

@media screen and (max-width: 768px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 10px 0 10px;
    height: 70px;
    box-shadow: 0 0px 5px rgba(77, 77, 77, 0.6);
  }
}

.header__logo {
  display: block;
  margin-top: 9px;
  float: left;
}

.header__logo img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .header__logo {
    float: none;
    width: 200px;
    margin: 0 auto 6px;
  }
}

.header-nav {
  float: right;
  display: flex;
  align-items: baseline;
}

@media screen and (max-width: 768px) {
  .header-nav {
    align-items: center;
    float: none;
    justify-content: space-around;
  }
}

.header-nav__item {
  font-size: 16px;
  margin: 0 10px;
  letter-spacing: 0.16em;
}

@media screen and (max-width: 768px) {
  .header-nav__item {
    font-size: 11px;
    letter-spacing: 0;
    margin: 0 5px;
  }
}

.header-nav__item--contact {
  font-size: 14px;
  width: 132px;
  height: 28px;
  border: 1px solid #005eaa;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  line-height: 28px;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .header-nav__item--contact {
    font-size: 11px;
    width: 95px;
    height: 22px;
    line-height: 22px;
  }
}

.header-nav__item--contact .header-nav__link {
  transition: all .3s;
}

.header-nav__item--contact .header-nav__link:hover {
  background: #005eaa;
  color: #fff;
  text-decoration: none;
}

.header-nav__item--phone {
  font-size: 31px;
  padding-top: 4px;
  padding-left: 33px;
  font-weight: bold;
  background-image: url("../img/top/ico_phone_01.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 28px auto;
  letter-spacing: 0.08em;
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .header-nav__item--phone {
    font-size: 13px;
    padding-left: 20px;
    background-size: 15px auto;
  }
}

.header-nav__link {
  color: #005eaa;
  display: block;
}

/* gnav
-------------------------*/
.gnav {
  position: absolute;
  top: 67px;
  left: 50%;
  width: 837px;
  transform: translateX(-50%);
  z-index: 99990;
}

@media screen and (max-width: 768px) {
  .gnav {
    top: 70px;
    width: 100%;
    left: 0;
    transform: translateX(0);
  }
}

.gnav__list {
  display: flex;
  justify-content: space-around;
  width: 837px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  background: #005eaa;
}

@media screen and (max-width: 768px) {
  .gnav__list {
    width: 100%;
    flex-wrap: wrap;
    padding: 0;
  }
}

.gnav__item {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .gnav__item {
    font-size: 11px;
    text-align: center;
    box-sizing: border-box;
  }
  .gnav__item:nth-child(1) {
    flex-basis: 26%;
    border-bottom: 1px solid #fff;
  }
  .gnav__item:nth-child(2) {
    flex-basis: 26%;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  .gnav__item:nth-child(3) {
    flex-basis: 48%;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  .gnav__item:nth-child(4) {
    flex-basis: 60%;
  }
  .gnav__item:nth-child(5) {
    border-left: 1px solid #fff;
    flex-basis: 40%;
  }
}

.gnav__link {
  display: block;
  position: relative;
  height: 72px;
  color: #fff;
  line-height: 72px;
  box-sizing: border-box;
  text-decoration: none;
  transition: .3s;
}

@media screen and (max-width: 768px) {
  .gnav__link {
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
  }
}

.gnav__link:after {
  position: absolute;
  bottom: 15px;
  left: 50%;
  content: '';
  width: 0;
  height: 1px;
  background-color: #fff;
  transition: .3s;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .gnav__link:after {
    display: none;
  }
}

.gnav__link:hover {
  text-decoration: none;
}

.gnav__link:hover:after {
  width: 100%;
}

/* breadcrumb
-------------------------*/
.breadcrumb {
  padding: 40px 0;
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb li {
  display: inline-block;
  color: #b2b2b2;
}

.breadcrumb li a {
  color: #b2b2b2;
}

.breadcrumb li + li {
  margin-left: 6px;
}

.breadcrumb li + li:before {
  margin-right: 6px;
  content: ">";
}

/* contents
-------------------------*/
.contents {
  margin-top: 72px;
}

@media screen and (max-width: 768px) {
  .contents {
    padding-top: 171px;
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .contents img {
    display: block;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0 auto;
  }
}

/* footer
-------------------------*/
.footer {
  position: relative;
  margin-bottom: 85px;
  padding: 80px 0 255px;
  background-image: url("../img/common/bg_footer_01.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 837px auto;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 0 140px;
    background-size: 100% auto;
  }
}

.footer:before {
  position: absolute;
  bottom: 26px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #005eaa;
  content: "";
  z-index: -1;
}

.footer__logo {
  display: block;
  margin-bottom: 25px;
  opacity: 1;
  transition: opacity 300ms;
}

.footer__logo:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .footer__logo {
    margin-bottom: 15px;
  }
}

.footer__address {
  margin-bottom: 50px;
  letter-spacing: 0.13em;
}

@media screen and (max-width: 768px) {
  .footer__address {
    margin-bottom: 30px;
  }
}

.footer__link {
  padding: 22px 0;
  background: #005eaa;
}

@media screen and (max-width: 768px) {
  .footer__link {
    padding: 20px 0 10px;
  }
}

.footer__list {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .footer__list {
    display: block;
  }
}

.footer__item {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.08em;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .footer__item {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 11px;
    border-right: 1px solid #fff;
    margin-right: 12px;
    padding-right: 12px;
  }
}

.footer__item:first-child {
  padding-left: 0;
}

.footer__item:first-child:before {
  display: none;
}

.footer__item:before {
  position: absolute;
  top: 0;
  left: -9px;
  width: 1px;
  height: 100%;
  background: #fff;
  content: "";
}

@media screen and (max-width: 768px) {
  .footer__item:before {
    display: none;
  }
}

.footer__item a {
  color: #fff;
}

.footer__copyright {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: #005eaa;
  letter-spacing: 0.13em;
  font-size: 9px;
  white-space: nowrap;
}

/* ------------------------------------------------
 	common  parts    
  ---------------------------------------------- */
.wrap, .fade {
  display: block;
  text-decoration: none;
}

.wrap:hover, .fade:hover {
  text-decoration: none;
}

.container {
  width: 837px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}

.ib {
  display: inline-block;
}

.phoneTxt {
  text-decoration: none;
  cursor: default;
}

.phoneTxt:hover {
  text-decoration: none;
}

.is_pc {
  display: block;
}

@media screen and (max-width: 768px) {
  .is_pc {
    display: none;
  }
}

.is_sp {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .is_sp {
    display: block !important;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ------------------------------------------------
  TOP
---------------------------------------------- */
.mv {
  position: relative;
  z-index: 80;
  clear: both;
  height: calc(100vh - 67px);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .mv {
    top: 151px;
    height: calc(100vh - 151px);
  }
}

.mv__catch {
  position: absolute;
  width: 837px;
  left: 50%;
  bottom: 160px;
  transform: translateX(-50%);
  font-size: 34px;
  font-weight: bold;
  text-shadow: #fff 2px 0px 0px, #fff -2px 0px 0px, #fff 0px -2px 0px, #fff 0px 2px 0px, #fff 2px 2px 0px, #fff -2px 2px 0px, #fff 2px -2px 0px, #fff -2px -2px 0px, #fff 1px 2px 0px, #fff -1px 2px 0px, #fff 1px -2px 0px, #fff -1px -2px 0px, #fff 2px 1px 0px, #fff -2px 1px 0px, #fff 2px -1px 0px, #fff -2px -1px 0px, #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px, #fff -1px -1px 0px;
  color: #000;
}

@media screen and (max-width: 768px) {
  .mv__catch {
    font-size: 20px;
    width: 80%;
    max-width: 320px;
    left: 0;
    transform: translateX(0);
    padding: 0 20px;
    box-sizing: border-box;
    bottom: 120px;
  }
  .mv__catch img {
    display: block;
    width: 100%;
  }
}

.mv__txt {
  position: absolute;
  width: 837px;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  font-size: 15px;
  color: #000;
  letter-spacing: 0.09em;
  text-shadow: #fff 2px 0px 0px, #fff -2px 0px 0px, #fff 0px -2px 0px, #fff 0px 2px 0px, #fff 2px 2px 0px, #fff -2px 2px 0px, #fff 2px -2px 0px, #fff -2px -2px 0px, #fff 1px 2px 0px, #fff -1px 2px 0px, #fff 1px -2px 0px, #fff -1px -2px 0px, #fff 2px 1px 0px, #fff -2px 1px 0px, #fff 2px -1px 0px, #fff -2px -1px 0px, #fff 1px 1px 0px, #fff -1px 1px 0px, #fff 1px -1px 0px, #fff -1px -1px 0px;
}

@media screen and (max-width: 768px) {
  .mv__txt {
    width: 100%;
    left: 0;
    bottom: 40px;
    transform: translateX(0);
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 13px;
  }
}

.top-slider {
  height: 100%;
  background: #fff;
}

.top-slider__item {
  width: 100%;
  height: calc(100vh - 67px);
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .top-slider__item {
    height: calc(100vh - 151px);
  }
}

.top-slider__item img {
  visibility: hidden;
}

.top-slider__item-inner {
  width: 100%;
  height: calc(100vh - 67px);
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1);
  transition: opacity 0ms linear 1500ms, transform 0ms linear 1500ms;
}

@media screen and (max-width: 768px) {
  .top-slider__item-inner {
    height: calc(100vh - 151px);
  }
}

.top-slider .top-slider__item.slick-current .top-slider__item-inner {
  opacity: 1;
  transform: scale(1.07);
  transition: opacity 1500ms cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 6000ms linear;
}

.top-slider .first-zoom {
  opacity: 1;
  transform: scale(1.05);
  transform: scale(1);
  transition: transform 8000ms ease-out;
}

.top-work {
  padding-top: 95px;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-work {
    padding-top: 191px;
  }
}

.top-work__head {
  margin-bottom: 40px;
  font-size: 19px;
  letter-spacing: 0.07em;
  color: #000;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-work__head {
    font-size: 13px;
  }
}

.top-work__head span {
  margin-top: 20px;
  padding: 0 15px;
  display: inline-block;
  background-image: url("../img/common/line_01.png");
  background-size: auto 12px;
  background-repeat: repeat-x;
  background-position: left 85%;
  font-size: 30px;
  letter-spacing: 0.09em;
}

@media screen and (max-width: 768px) {
  .top-work__head span {
    margin-top: 0;
    font-size: 20px;
  }
}

.top-work__list {
  display: flex;
  margin: 0 -10px;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .top-work__list {
    margin: 0 5px;
    flex-wrap: wrap;
  }
}

.top-work__item {
  position: relative;
  margin: 0 10px;
  width: 33.3%;
  height: 364px;
}

@media screen and (max-width: 768px) {
  .top-work__item {
    width: 50%;
    height: 200px;
    margin: 0 0 15px;
    padding: 0 5px;
    box-sizing: border-box;
  }
}

.top-work__item--01 .top-work__link:after {
  background-image: url("../img/top/img_work_01.png");
}

.top-work__item--02 .top-work__link:after {
  background-image: url("../img/top/img_work_02.png");
}

.top-work__item--03 .top-work__link:after {
  background-image: url("../img/top/img_work_03.png");
}

.top-work__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .top-work__link {
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.top-work__link:before {
  position: absolute;
  top: 58px;
  left: 50%;
  width: 93px;
  height: 101px;
  background-image: url("../img/top/ico_point_01.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 93px auto;
  transform: translateX(-50%);
  content: "";
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .top-work__link:before {
    top: 5px;
    left: 50%;
    width: 50px;
    transform: translateX(-50%);
    background-size: 50px auto;
  }
}

.top-work__link:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all .3s;
  z-index: 2;
}

.top-work__link:hover {
  text-decoration: none;
}

.top-work__link:hover:after {
  transform: scale(1.1);
}

.top-work__link:hover .top-work__btn {
  opacity: .7;
}

.top-work__ttl {
  position: relative;
  padding-top: 175px;
  margin-bottom: 10px;
  z-index: 4;
  color: #fff;
  font-size: 24px;
  line-height: 1.375;
  letter-spacing: 0.05em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-work__ttl {
    padding-top: 70px;
    padding-left: 0;
    font-size: 15px;
  }
}

@media screen and (max-width: 359px) {
  .top-work__ttl {
    font-size: 13px;
  }
}

.top-work__btn {
  position: relative;
  display: block;
  background: #ffff00;
  margin: 0 auto;
  z-index: 4;
  max-width: 280px;
  height: 42px;
  line-height: 42px;
  letter-spacing: 0.05em;
  font-size: 15px;
  transition: all .3s;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-work__btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    font-size: 11px;
    height: 30px;
    line-height: 30px;
  }
}

.top-work__btn:before {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 9px;
  height: 11px;
  background-image: url("../img/common/ico_link_01.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  transform: translateY(-50%);
  content: "";
}

@media screen and (max-width: 768px) {
  .top-work__btn:before {
    right: 10px;
  }
}

.top-news {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top-news {
    padding: 40px 0;
  }
}

.top-news__head {
  display: inline-block;
  margin-bottom: 35px;
  padding: 0 15px;
  background-image: url("../img/common/line_01.png");
  background-size: auto 12px;
  background-repeat: repeat-x;
  background-position: left 80%;
  font-size: 30px;
  letter-spacing: 0.05em;
  color: #000;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-news__head {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.top-news__item {
  border-bottom: 2px dotted #d0d0d0;
}

.top-news__link {
  padding: 10px 0;
  display: flex;
  letter-spacing: 0.05em;
  line-height: 2;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .top-news__link {
    font-size: 13px;
  }
}

.top-news__link:hover {
  text-decoration: none;
}

.top-news__link:hover .top-news__ttl {
  text-decoration: underline;
}

.top-news__date {
  padding-left: 120px;
}

@media screen and (max-width: 768px) {
  .top-news__date {
    padding-left: 0;
  }
}

.top-news__ttl {
  padding-left: 35px;
}

@media screen and (max-width: 768px) {
  .top-news__ttl {
    padding-left: 20px;
    text-align: left;
  }
}

.top-contact {
  padding: 30px 0;
  background: #eee;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .top-contact {
    padding: 20px 20px;
  }
}

.top-contact .container {
  padding: 105px 0 40px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .top-contact .container {
    padding: 70px 20px 40px;
  }
}

.top-contact__head {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  padding: 0 15px;
  background-image: url("../img/common/line_01.png");
  background-size: auto 12px;
  background-repeat: repeat-x;
  background-position: left 80%;
  font-size: 30px;
  letter-spacing: 0.05em;
  color: #000;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-contact__head {
    font-size: 20px;
  }
}

.top-contact__head:before {
  position: absolute;
  top: -74px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 64px;
  height: 66px;
  background-image: url("../img/top/ico_contact_01.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 64px auto;
}

@media screen and (max-width: 768px) {
  .top-contact__head:before {
    top: -50px;
    background-size: 48px auto;
    width: 48px;
  }
}

.top-contact__ttl {
  font-size: 20px;
  letter-spacing: 0.11em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .top-contact__ttl {
    font-size: 15px;
  }
}

.top-contact__phone {
  padding-top: 10px;
  padding-left: 50px;
  font-size: 53px;
  letter-spacing: 0.08em;
  font-weight: bold;
  background-image: url("../img/top/ico_phone_01.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 42px auto;
  color: #4d4d4d;
}

@media screen and (max-width: 768px) {
  .top-contact__phone {
    font-size: 30px;
    padding-bottom: 5px;
    background-size: 34px auto;
    padding-left: 40px;
  }
}

@media screen and (max-width: 359px) {
  .top-contact__phone {
    font-size: 24px;
    background-size: 28px auto;
  }
}

.top-contact__note {
  letter-spacing: 0.13em;
}

@media screen and (max-width: 768px) {
  .top-contact__note {
    font-size: 11px;
  }
}

.top-contact__note + .top-contact__ttl {
  margin-top: 40px;
}

.top-contact__btn {
  position: relative;
  display: block;
  width: 100%;
  max-width: 334px;
  height: 56px;
  background: #005eaa;
  border-radius: 5px;
  margin: 20px auto 25px;
  color: #fff;
  line-height: 56px;
  letter-spacing: 0.1em;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 300ms;
}

.top-contact__btn:hover {
  opacity: .7;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .top-contact__btn {
    font-size: 13px;
    margin: 5px auto 10px;
  }
}

.top-contact__btn:before {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 9px;
  height: 11px;
  background-image: url("../img/common/ico_link_02.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  transform: translateY(-50%);
  content: "";
}

.top-contact__btn:hover {
  text-decoration: none;
}

/* ------------------------------------------------
  page
---------------------------------------------- */
.page__ttl {
  font-size: 30px;
  font-weight: bold;
  color: #005ea9;
  letter-spacing: 0.06em;
}

@media screen and (max-width: 768px) {
  .page__ttl {
    font-size: 24px;
  }
}

.page__en-ttl {
  display: block;
  color: #005ea9;
  font-size: 20px;
  letter-spacing: 0.06em;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .page__en-ttl {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

.page__heading {
  color: #005ea9;
  font-weight: bold;
  font-size: 19px;
  line-height: 2.1;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .page__heading {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

.page__txt {
  margin-bottom: 90px;
  line-height: 2.66;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  .page__txt {
    line-height: 2;
    margin-bottom: 60px;
  }
}

.page__txt--center {
  text-align: center;
}

.page__txt a {
  color: #005ea9;
}

.page__txt + .page__img {
  margin-top: -50px;
}

.page__img-ttl {
  margin-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .page__img-ttl {
    margin-bottom: 20px;
    overflow: hidden;
  }
  .page__img-ttl img {
    max-width: inherit;
    width: auto;
    height: 100px;
  }
}

.page__img {
  margin-bottom: 10px;
}

.item-display {
  position: relative;
  background-image: url("../img/page/bg_item_01.png");
  background-repeat: repeat-x;
  background-size: auto 427px;
  text-align: center;
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .item-display__logo {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
}

.item-display__name {
  position: absolute;
  bottom: 40px;
  left: 50%;
  font-size: 14px;
  font-weight: bold;
  margin-left: 110px;
}

@media screen and (max-width: 768px) {
  .item-display__name {
    font-size: 10px;
    bottom: 5px;
    white-space: nowrap;
    transform: translateX(-50%);
    margin-left: 0;
  }
}

.page-contact {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .page-contact {
    margin-bottom: 60px;
  }
}

.page-contact__ttl {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  .page-contact__ttl {
    font-size: 18px;
    white-space: nowrap;
    margin-bottom: 20px;
  }
}

.page-contact__ttl-inner {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  background-image: url("../img/common/line_01.png");
  background-size: auto 12px;
  background-repeat: repeat-x;
  background-position: left 80%;
}

@media screen and (max-width: 768px) {
  .page-contact__ttl-inner {
    padding: 0 10px;
  }
}

.page-contact__ttl-inner:before {
  position: absolute;
  top: 6px;
  left: -14px;
  transform: rotate(-45deg);
  width: 1px;
  height: 35px;
  background: #4d4d4d;
  content: "";
}

@media screen and (max-width: 768px) {
  .page-contact__ttl-inner:before {
    top: 2px;
    left: -10px;
    height: 30px;
  }
}

.page-contact__ttl-inner:after {
  position: absolute;
  top: 6px;
  right: -14px;
  transform: rotate(45deg);
  width: 1px;
  height: 35px;
  background: #4d4d4d;
  content: "";
}

@media screen and (max-width: 768px) {
  .page-contact__ttl-inner:after {
    top: 2px;
    right: -10px;
    height: 30px;
  }
}

.page-contact__list {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .page-contact__list {
    display: block;
  }
}

.page-contact__list li {
  width: 378px;
  border: 1px solid #86c440;
  border-radius: 5px;
  height: 56px;
  box-sizing: border-box;
  line-height: 56px;
}

@media screen and (max-width: 768px) {
  .page-contact__list li {
    margin: 0 auto 15px;
    width: 100%;
    max-width: 378px;
  }
}

.page-contact__phone {
  display: flex;
  align-items: center;
  height: 54px;
  line-height: 54px;
  text-decoration: none;
  cursor: default;
}

.page-contact__phone:hover {
  text-decoration: none;
}

.page-contact__number {
  line-height: 56px;
  font-size: 26px;
  color: #86c440;
  letter-spacing: 0.12em;
  white-space: nowrap;
  padding-left: 58px;
  background-image: url("../img/page/ico_phone_02.png");
  background-repeat: no-repeat;
  background-position: 20px 8px;
  background-size: 28px auto;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .page-contact__number {
    padding-left: 38px;
    background-size: 18px auto;
    background-position: 15px 15px;
    font-size: 20px;
  }
}

.page-contact__link {
  display: block;
  background: #86c440;
  text-align: center;
  height: 54px;
  line-height: 54px;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 300ms;
}

.page-contact__link:hover {
  opacity: .7;
  text-decoration: none;
}

.page-contact__note {
  color: #b2b2b2;
  padding-left: 10px;
  font-size: 10px;
  line-height: 1.3;
}
